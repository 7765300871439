<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @edit="onEdit"
            @delete="onDelete"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { DecisionTargetModel } from '@/modules/decision-target/decision-target-model';

    const { fields } = DecisionTargetModel;

    export default {
        name: 'DecisionTargetListTable',
        data() {
            return {
                presenter: DecisionTargetModel.presenter,
                actions: [
                    {
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary'
                    },
                    {
                        name: 'delete',
                        icon: 'trash',
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        customIcon: false,
                        type: 'primary'
                    }
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'decisionTarget/list/rows',
            }),
            fields() {
                return [
                    fields.name,
                ]
            }
        },
        methods: {
            ...mapActions({
                selectRecord: 'decisionTarget/form/select',
                deleteRecord: 'decisionTarget/form/remove',
            }),
            async onEdit(data) {
                await this.selectRecord(data.item);
            },
            async onDelete(data) {
                await this.deleteRecord(data.item.id);
                this.$emit('update');
            },
        },
    };
</script>

<style scoped>

</style>
