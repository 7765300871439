<template>
    <div>
        <st-button
            variant="secondary"
            :callback="addDecisionTarget"
        >
            <i class="fa fa-plus"></i>
            {{ $t('DECISION_TARGET.ADD_BUTTON') }}
        </st-button>

        <decision-target-form-modal
            v-if="showModal"
            ref="decisionTargetFormModal"
            @close="dismissModal"
            :edit-mode="editMode"
        ></decision-target-form-modal>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import DecisionTargetFormModal from '@/modules/decision-target/components/DecisionTargetFormModal.vue';

    export default {
        name: 'DecisionTargetListToolbar',
        components: { DecisionTargetFormModal },
        watch: {
            openEditing(newValue, oldValue) {
                if (newValue === oldValue || !newValue) return false;
                this.editMode = true;
                this.showModal = true;
          }
        },
        data() {
            return {
                showModal: false,
                editMode: false,
            };
        },
        computed: {
            ...mapGetters({
                record: 'decisionTarget/form/record',
                openEditing: 'decisionTarget/form/openEditing'
            }),
        },
        methods: {
            ...mapActions({
                unselectRecord: 'decisionTarget/form/unselect',
            }),
            addDecisionTarget() {
                this.showModal = true;
                this.editMode = false;
            },
            dismissModal() {
                this.showModal = false;
                if (this.editMode) {
                    this.unselectRecord();
                }
            }
        }
    };
</script>

