<template>
  <st-page :title="$t('MENU.TARGET_LIST')">
    <loader v-if="isLoading" />
    <decision-target-list-filter ref="decisionTargetListFilter">
      <template #toolbar>
        <decision-target-list-toolbar />
      </template>
      <decision-target-list-table @update="updateList"/>
    </decision-target-list-filter>
  </st-page>
</template>

<script>
import { mapGetters } from "vuex";
import DecisionTargetListToolbar from "@/modules/decision-target/components/DecisionTargetListToolbar.vue";
import DecisionTargetListTable from "@/modules/decision-target/components/DecisionTargetListTable.vue";
import DecisionTargetListFilter from "@/modules/decision-target/components/DecisionTargetListFilter.vue";

export default {
  name: "DecisionTargetList",
  components: {
      DecisionTargetListToolbar,
      DecisionTargetListTable,
      DecisionTargetListFilter
  },
  computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        loadingFetch() {
            return this.loading['decisionTarget/get'];
        },
        isLoading() {
            return this.loadingFetch;
        },
    },
    methods: {
      updateList() {
        this.$refs.decisionTargetListFilter.refresh();
      },
    },
};
</script>
